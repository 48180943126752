<template>
  <div>
    <b-modal
      id="modal-sm-invitations-user"
      no-close-on-esc
      no-close-on-backdrop
      centered
      modal-class="no-header-modal"
      :hide-header="true"
    >
      <div style="padding: 0.8rem 1.4rem"></div>
      <b-card-text>
        <div class="new-invite-modal-title">
          {{ $t("user.UpdateUserRole") }}
        </div>
        <div class="you-invite"></div>
        <validation-observer ref="signupValidation" #default="{ invalid }">
          <b-form class="mtt-37">
            <b-form-group :label="$t('user.FullName')">
              <validation-provider
                #default="{ errors }"
                name="Email"
                :rules="{ required: true, email: true }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <span style="font-size: 16px; font-weight: bold">
                    {{ fullName() }}
                  </span>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('user.Role') + '*'">
              <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
              <validation-provider
                #default="{ errors }"
                name="Role"
                :rules="{ required: true }"
              >
                <v-select
                  id="vue-select"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  v-model="invite.role"
                  :placeholder="$t('user.Selectrole')"
                  :options="roles"
                  :reduce="(option) => option.id"
                  label="role_name"
                />

                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider>
            </b-form-group>
            <small class="text-danger">{{ hasErr }}</small>
            <div class="d-flex justify-content-center mb-64 mtt-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="invalid"
                @click="userRoleUpdate"
              >
                {{ $t("user.Update") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="close"
                class="mt-2"
              >
                {{ $t("user.Cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BPagination,
  BFormGroup,
  BModal,
  BCardText,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AccountService from "@/libs/api/account-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";

export default {
  name: "SentInvitations",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BModal,
    BForm
  },
  directives: {
    Ripple
  },
  data() {
    return {
      is_role_update: false,
      hasErr: "",

      invite: {
        email: "",
        role: ""
      }
    };
  },
  props: ["refreshed", "selectedAccount", "selectedUser", "page", "roles"],
  mounted() {
    if (this.selectedUser && this.selectedUser.id) {
      this.invite.email = this.selectedUser.email;
      this.invite.role = this.selectedUser.main_role;
      this.is_role_update = true;
    }
  },
  created() {
    if (this.selectedUser && this.selectedUser.id) {
      this.invite.email = this.selectedUser.email;
      this.invite.role = this.selectedUser.main_role;
      this.is_role_update = true;
    }
  },
  watch: {
    selectedUser() {
      if (this.selectedUser && this.selectedUser.id) {
        this.invite.email = this.selectedUser.email;
        this.invite.role = this.selectedUser.main_role;
        this.is_role_update = true;
      }
    }
  },
  methods: {
    async getRoles() {
      try {
        let obj = {};
        if (this.selectedAccount && this.selectedAccount.id) {
          obj = {
            account_id: this.selectedAccount.id,
            type: this.selectedAccount.type
          };
        }
        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().getSubDealerAccountUserRoles(obj)
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().getSubConsumerAccountUserRoles(obj)
              : await new AccountService().getSubAccountUserRoles(obj)
            : await new AccountService().getUserRolesRead(obj);

        if (response && response.data) {
          this.roles = response.data.list || [];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    async userRoleUpdate() {
      try {
        if (!this.is_role_update) {
          return;
        }
        const roleObj = {
          role: this.invite.role,
          user_id: this.selectedUser.id
        };
        if (this.selectedAccount && this.selectedAccount.id) {
          roleObj.account_id = this.selectedAccount.id || null;
        }
        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().updateSubDealerAccountUserRole(
                  roleObj
                )
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().updateSubConsumerAccountUserRole(
                  roleObj
                )
              : await new AccountService().updateSubAccountUserRole(roleObj)
            : await new AccountService().updateUserRole(roleObj);
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("user.UserRoleUpdatedTitle"),
              text: this.$t("user.UserRoleUpdated"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.close();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    close() {
      if (this.selectedUser && this.selectedUser.email) {
        this.invite.email = this.selectedUser.email;
        this.invite.role = this.selectedUser.main_role;
      }
      this.$bvModal.hide("modal-sm-invitations-user");

      this.refreshed(true);
    },
    fullName() {
      if (this.selectedUser) {
        let firstName = this.selectedUser && this.selectedUser.first_name;
        let lastName = this.selectedUser && this.selectedUser.last_name;

        return firstName + " " + lastName;
      } else {
        return "";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
</style>

<template>
  <div>
    <!-- Table Container Card -->
    <b-modal
      id="modal-sm-invitations"
      no-close-on-esc
      no-close-on-backdrop
      centered
      modal-class="no-header-modal"
      :hide-header="true"
      size="lg"
    >
      <div style="padding: 0.8rem 1.4rem"></div>
      <b-card-text ref="inviteModalInstance">
        <div class="new-invite-modal-title mb-2" v-if="isList">
          {{ $t("user.SuccessFulInvitationList") }}
        </div>
        <div class="new-invite-modal-title" v-else>
          {{ $t("user.SendInvite") }}
        </div>
        <div class="you-invite" v-if="!isList">
          {{ $t("user.Invitenewmembers") }}
        </div>
        <div v-if="!isList">
          <validation-observer ref="signupValidation" #default="{ invalid }">
            <b-form class="mtt-37">
              <b-form-group :label="$t('user.EmailsInfo')">
                <!-- {{ invite.usersData }} -->
                <multi-invitations
                  :inviteModalInstance="$refs.inviteModalInstance"
                  :role="invite.role"
                  v-model="invite.usersData"
                  :getValidEmail="getValidEmail"
                ></multi-invitations>
              </b-form-group>
              <b-form-group :label="$t('user.Role') + '*'">
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  :rules="{ required: true }"
                >
                  <v-select
                    id="vue-select"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    v-model="invite.role"
                    :placeholder="$t('user.Selectrole')"
                    :options="roles"
                    :reduce="(option) => option.id"
                    label="role_name"
                  />
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
              <small class="text-danger">{{ hasErr }}</small>
              <div class="d-flex justify-content-center mb-64 mtt-50">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  :disabled="
                    invalid ||
                    !Object.keys(invite.usersData) ||
                    isInvalidEmail ||
                    !Object.keys(invite.usersData).length
                  "
                  @click="inviteSent"
                >
                  {{ $t("user.Invite") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="close"
                  class="mt-2"
                >
                  {{ $t("user.Cancel") }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-if="isList" class="sentInvitations">
          <!-- Table Top -->

          <div class="table-responsive"></div>
          <b-table
            class="position-relative header-left-align max-50-vh"
            responsive
            show-empty
            align-v="end"
            :items="listItems"
            :fields="tableColumns"
            :empty-text="$t('NoMatchingRecordsFound')"
          >
            <!-- {{ listItems }} -->
            <template #cell(email)="row">
              <b-media class="align-items-center">
                <template #aside>
                  <b-avatar
                    size="35"
                    src="RA"
                    :text="row.item.avatar_txt"
                    :variant="row.item.variant"
                  />
                </template>
                <h6 class="mb-0">
                  {{
                    row.item.user
                      ? row.item.user.first_name + " " + row.item.user.last_name
                      : row.item.email
                  }}
                </h6>
                <small v-if="row.item.user">{{ row.item.email }}</small>
              </b-media>
            </template>
            <template #cell(invitationLink)="row">
              <div
                v-if="row.item.status == 'Accepted'"
                class="d-flex align-items-center justify-content-end"
              >
                <b-badge class="badge-cust-accepted" variant="light-success">
                  {{ row.item.status }}</b-badge
                >
              </div>

              <div class="d-flex align-items-center justify-content-end" v-else>
                <div
                  style="position: relative; cursor: pointer"
                  class="link-hover"
                >
                  <span class="link-show">
                    <h5 class="text-white">{{ $t("user.CopyLink") }}</h5>
                    <div class="link-text-copy">
                      {{ row.item.invitationLink }}
                    </div>
                  </span>
                  <span class="text-primary">{{
                    formattedDescriptions(row.item.invitationLink, 30)
                  }}</span>
                </div>
                <span>
                  <div
                    :id="'textToCopy' + row.item.invitationId"
                    class="invitation-link-hidden"
                  >
                    {{ row.item.invitationLink }}
                  </div>
                  <feather-icon
                    icon="CopyIcon"
                    class="text-primary action-icon"
                    size="18"
                    @click="copyText('textToCopy' + row.item.invitationId)"
                    style="position: relative; cursor: pointer"
                  ></feather-icon>
                </span>
              </div>
            </template>
          </b-table>

          <div class="d-flex justify-content-center mb-64 mtt-50">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="close"
              class="mt-2"
            >
              {{ $t("user.Close") }}
            </b-button>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BPagination,
  BFormGroup,
  BModal,
  BCardText,
  BForm,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AccountService from "@/libs/api/account-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MultiInvitations from "@/layouts/components/UserOnboarding/MultiInvitations.vue";
export default {
  name: "SentInvitations",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BModal,
    BForm,
    MultiInvitations,
    BSkeletonTable,
    VBTooltip
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },

  data() {
    return {
      tableColumns: [
        { key: "email", Class: "", label: this.$t("user.UserName") },
        {
          key: "invitationLink",
          thClass: "text-right",
          label: this.$t("user.InvitationUrl")
        }
      ],
      is_role_update: false,
      hasErr: "",
      invite: {
        usersData: [],
        role: ""
      },
      listItems: [],
      isList: false,
      isInvalidEmail: true
    };
  },

  props: ["refreshed", "selectedAccount", "selectedUser", "page", "roles"],
  methods: {
    async getRoles() {
      try {
        let obj = {};

        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().getSubDealerAccountUserRoles(obj)
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().getSubConsumerAccountUserRoles(obj)
              : await new AccountService().getSubAccountUserRoles(obj)
            : await new AccountService().getUserRolesRead(obj);

        if (response && response.data) {
          this.roles = response.data.list || [];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    async inviteSent() {
      try {
        let usersData = {};
        let emails = [];
        let userId = [];
        if (this.invite.usersData && this.invite.usersData.length) {
          this.invite.usersData.map((e) => {
            if (!e.id) {
              emails.push(e.toLowerCase());
            } else {
              userId.push(e.id);
            }
            usersData = {
              emails: emails,
              userId: userId
            };
            return usersData;
          });
        }

        this.invite.usersData = usersData;

        if (this.selectedAccount && this.selectedAccount.id) {
          this.invite.account_id = this.selectedAccount.id || null;
        }
        if (this.invite.usersData.userId || this.invite.usersData.emails) {
          let response =
            this.selectedAccount && this.selectedAccount.id
              ? this.selectedAccount.type === "DEALER"
                ? await new AccountService().inviteMultipleUserSubDealerAccount(
                    this.invite
                  )
                : this.selectedAccount.type === "CONSUMER"
                ? await new AccountService().inviteMultipleUserSubConsumerAccount(
                    this.invite
                  )
                : await new AccountService().inviteMultipleUserSubAccount(
                    this.invite
                  )
              : await new AccountService().inviteMultipleUser(this.invite);
          if (response && response.data) {
            this.isList = true;
            this.listItems = response.data.invitedUsers;
            this.listItems = this.listItems.map((a) => {
              a.variant = this.getRandomBgColor();
              a.avatar_txt = a.user
                ? a.user.first_name.substring(0, 1) +
                  (a.user.last_name && a.user.last_name
                    ? a.user.last_name.substring(0, 1)
                    : "")
                : a.email.substring(0, 1)
                ? a.email.substring(0, 1)
                : "";
              return a;
            });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("user.InvitationSentTitle"),
                text: this.$t("user.InvitationSent"),
                icon: "EditIcon",
                variant: "success"
              }
            });
            // this.close();
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    formattedDescriptions(description, len) {
      return description && description.length > len
        ? `${description.slice(0, len).trim()}...`
        : description;
    },
    close() {
      this.$bvModal.hide("modal-sm-invitations");
      this.invite = {
        usersData: "",
        role: ""
      };
      this.isList = false;
      this.refreshed(true);
    },
    copyText(elementId) {
      const textElement = document.getElementById(elementId);
      const range = document.createRange();
      range.selectNode(textElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand("copy");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("user.InvitationLinkCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "success"
          }
        });
      } catch (error) {
        console.error("Unable to copy text: ", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("user.InvitationLinkFailedCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }

      // Clear the selection
      window.getSelection().removeAllRanges();
    },
    getValidEmail(isInvalid) {
      if (isInvalid) {
        this.isInvalidEmail = true;
      } else {
        this.isInvalidEmail = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
.max-50-vh {
  max-height: 50vh;
  overflow-y: auto;
  // &.table td {
  //   padding: 10px 10px !important;
  // }
}
.table-responsive.max-50-vh table {
  min-height: 50px;
}
.sentInvitations {
  .table-responsive {
    height: 0%;
  }
}
.action-width {
  max-width: 125px;
  width: 125px;
  min-width: 125px;
}
.link-hover {
  .link-show {
    display: none;
  }
  &:hover {
    .link-show {
      display: block;
      background-color: var(--primary);
      color: var(--white);
      position: absolute;
      bottom: 18px;
      max-width: 651px;
      min-width: 650px;
      right: 0px;
      padding: 5px 10px;
      border-radius: 6px;
    }
  }
  .link-text-copy {
    font-size: 11px;
    letter-spacing: -0.5px;
  }
}
.invitation-link-hidden {
  display: block;
  max-width: 1px;
  position: absolute;
  height: 1px;
  opacity: 0;
  z-index: -9;
  right: 0px;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  .link-hover:hover .link-show {
    max-width: 351px;
    min-width: 350px;
    bottom: 0px;
  }
}
</style>

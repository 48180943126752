<template>
  <div>
    <b-card class="mt-36 added-data" v-if="isGroupEntity">
      <div class="adduser-btn-row">
        <h4 class="card-title">
          {{ $t("user.Userlist") }}
        </h4>
      </div>
      <UserListComp v-if="isGroupEntity" />
    </b-card>
    <b-tabs pills v-if="!isGroupEntity">
      <!-- Tab: Account -->
      <b-tab
        :active="selected_tab === 'users'"
        @click="activeTab('users', false)"
        lazy
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.READ,
            subject: constants.PERMISSIONS_MODEL.USERS
          })
        "
      >
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("user.User") }}</span>
        </template>
        <b-card class="mt-36 added-data">
          <div class="adduser-btn-row">
            <h4 class="card-title">
              {{ $t("user.Userlist") }}
            </h4>
            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('tooTip.AddInvite')"
              variant="primary"
              class="btn-icon"
              @click="updateUserRoll"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
                })
              "
            >
              <feather-icon icon="UserPlusIcon" />
              <span class="d-none d-sm-inline ml-1">{{
                $t("tooTip.AddInvite")
              }}</span>
            </b-button>
          </div>
          <UserListComp :isSentInvite="isSentInvite" />
        </b-card>
      </b-tab>

      <!-- Tab: Information -->

      <b-tab
        :active="selected_tab === 'invitations'"
        @click="activeTab('invitations', false)"
        lazy
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.READ,
            subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
          })
        "
      >
        <template #title>
          <feather-icon icon="UserPlusIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("user.invitations") }}</span>
        </template>
        <b-card class="mt-36 added-data">
          <div class="adduser-btn-row">
            <h4 class="card-title">
              {{ $t("user.ListInvitations") }}
            </h4>
            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('tooTip.AddInvite')"
              variant="primary"
              class="btn-icon"
              @click="updateUserRoll"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
                })
              "
            >
              <feather-icon icon="UserPlusIcon" />
              <span class="d-none d-sm-inline ml-1">{{
                $t("tooTip.AddInvite")
              }}</span>
            </b-button>
          </div>
          <InvitationsList :isSentInvite="isSentInvite" />
        </b-card>
      </b-tab>
      <b-tab
        :active="selected_tab === 'roles'"
        @click="activeTab('roles', false)"
        lazy
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.READ,
            subject: constants.PERMISSIONS_MODEL.ROLE
          })
        "
      >
        <template #title>
          <feather-icon icon="ToolIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{
            $t("roles_management.Roles")
          }}</span>
        </template>

        <RolesList />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import RolesList from "@/views/RolesManagement/RoleList.vue";
import UserListComp from "@/layouts/components/UserOnboarding/UserListComp.vue";
import InvitationsList from "@/layouts/components/UserOnboarding/InvitationsList.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserListComp,
    InvitationsList,
    RolesList,
    BButton,
    BTooltip,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      isSentInvite: false,
      selected_tab: "users"
    };
  },
  props: ["isGroupEntity", "groupName"],
  watch: {
    $route(to, from) {
      if (this.$route.query.tab) {
        this.selected_tab = this.$route.query.tab;
        this.activeTab(this.$route.query.tab, true);
      }
    }
  },
  mounted() {
    if (this.$route.query.tab) {
      this.selected_tab = this.$route.query.tab;
      this.activeTab(this.$route.query.tab, true);
      // this.$router.replace({ query: null });
    }
  },
  methods: {
    updateUserRoll() {
      this.isSentInvite = true;

      this.$bvModal.show("modal-sm-invitations");
    },
    activeTab(tab, isRedirect) {
      //    this.$router.replace({ query: tab }).catch((e) => {});
      if (!isRedirect) {
        this.$router.push({ name: "user-list", query: { tab: tab } });
      }

      this.$route.meta.breadcrumb = JSON.parse(
        JSON.stringify(constants.USER_ROUTE_META_BREADCRUMB)
      );
      let breadcrumb;
      if (tab == "invitations") {
        breadcrumb = {
          text: "Breadcrumb_Invitations",
          index: "invitations",
          active: true
        };
        this.$route.meta.breadcrumb.push(breadcrumb);
      } else if (tab == "roles") {
        breadcrumb = {
          text: "Breadcrumb_Roles",
          index: "roles",
          active: true
        };
        this.$route.meta.breadcrumb.push(breadcrumb);
      } else {
        const breadcrumbs = JSON.parse(
          JSON.stringify(constants.USER_ROUTE_META_BREADCRUMB)
        );
        breadcrumbs[0].active = true;
        this.$route.meta.breadcrumb = breadcrumbs;
      }
    }
  }
};
</script>

<style lang="scss">
@import "./index.scss";
</style>

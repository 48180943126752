var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-select multi-invitation-1"},[_c('v-select',{ref:"select",staticClass:"multi-invitation",attrs:{"multiple":"","taggable":"","placeholder":_vm.$t('ExampleEmail'),"reduce":function (option) { return option; },"label":"name","filterBy":_vm.filterByInvitations},on:{"search":_vm.filterData,"input":_vm.tagFormatter},scopedSlots:_vm._u([{key:"option",fn:function(ref){
      var name = ref.name;
      var profile_pic_url = ref.profile_pic_url;
      var avatar_txt = ref.avatar_txt;
      var variant = ref.variant;
      var username = ref.username;
      var isNew = ref.isNew;
      var isAccepted = ref.isAccepted;
return [_c('div',{staticClass:"d-flex align-items-center",class:{
          'disabled-content': isAccepted
        }},[(isNew)?_c('b-avatar',{attrs:{"size":"35","src":profile_pic_url,"text":avatar_txt,"variant":variant}}):_vm._e(),_c('span',{staticClass:"ml-1"},[(!isNew && !_vm.isTrue)?_c('div',{staticClass:"ml-50 text-body",class:{
              'is-new-content': _vm.newEmail
            }},[_vm._v(" "+_vm._s(_vm.newEmail)+" ")]):_vm._e(),(isNew)?_c('div',{staticClass:"text-body"},[_c('b',[_vm._v(_vm._s(name)+" ")]),(isAccepted)?_c('span',{staticClass:"mt-15"},[_vm._v("("+_vm._s(_vm.$t("user.UserAlreadyExist"))+")")]):_vm._e()]):_vm._e(),_c('div',[_vm._v(_vm._s(username))])])],1)]}},{key:"selected-option",fn:function(ref){
            var profile_pic_url = ref.profile_pic_url;
            var name = ref.name;
            var avatar_txt = ref.avatar_txt;
            var variant = ref.variant;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"size":"18","src":profile_pic_url,"text":avatar_txt,"variant":variant}}),_c('span',{staticClass:"ml-50 text-white"},[_vm._v(_vm._s(name))])],1)]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
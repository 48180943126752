<template>
  <div class="tag-select multi-invitation-1">
    <v-select
      v-model="selectedUsers"
      multiple
      ref="select"
      taggable
      :placeholder="$t('ExampleEmail')"
      :reduce="(option) => option"
      label="name"
      class="multi-invitation"
      @search="filterData"
      @input="tagFormatter"
      :filterBy="filterByInvitations"
    >
      <template
        #option="{
          name,
          profile_pic_url,
          avatar_txt,
          variant,
          username,
          isNew,
          isAccepted
        }"
      >
        <div
          class="d-flex align-items-center"
          :class="{
            'disabled-content': isAccepted
          }"
        >
          <b-avatar
            v-if="isNew"
            size="35"
            :src="profile_pic_url"
            :text="avatar_txt"
            :variant="variant"
          />
          <span class="ml-1">
            <div
              v-if="!isNew && !isTrue"
              class="ml-50 text-body"
              :class="{
                'is-new-content': newEmail
              }"
            >
              {{ newEmail }}
            </div>
            <div class="text-body" v-if="isNew">
              <b>{{ name }} </b>
              <span v-if="isAccepted" class="mt-15"
                >({{ $t("user.UserAlreadyExist") }})</span
              >
            </div>
            <div>{{ username }}</div>
          </span>
        </div>
      </template>
      <template
        #selected-option="{ profile_pic_url, name, avatar_txt, variant }"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            size="18"
            :src="profile_pic_url"
            :text="avatar_txt"
            :variant="variant"
          />
          <span class="ml-50 text-white">{{ name }}</span>
        </div>
      </template>
    </v-select>
  </div>
</template>
<script>
import { BAvatar } from "bootstrap-vue";
import vSelect from "vue-select";
import UtilityService from "@/libs/api/utility-service";
import AccountService from "@/libs/api/account-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vSelect,
    BAvatar
  },

  data() {
    return {
      selectedUsers: [],
      availableUsers: [],
      isNew: false,
      newEmail: "",
      isTrue: false,
      isEmailValid: true,
      filter: {},
      isDisabled: false,
      selectedValue: null
    };
  },
  mounted() {
    const me = this;
    setTimeout(() => {
      const selectInput = document.querySelector(".tag-select .vs__search");
      selectInput.focus();
      me.inviteModalInstance.addEventListener("click", me.handleOutsideClick);
      me.inviteModalInstance.addEventListener("keydown", (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
          me.handleOutsideClick(null, true);
        }
      });

      // Attach paste event listener to the input element inside v-select
      me.inviteModalInstance.addEventListener("paste", (event) => {
        setTimeout(() => {
          const search = this.$refs.select.search;
          this.$refs.select.search = "";
          this.emailValidator(search);
        }, 100);
      });
    }, 300);
  },
  beforeDestroy() {
    if (this.inviteModalInstance) {
      this.inviteModalInstance.removeEventListener("click", (event) => {});
      this.inviteModalInstance.removeEventListener("keydown", (event) => {});
      this.inviteModalInstance.removeEventListener("paste", (event) => {});
    }
  },
  props: [
    "value",
    "isList",
    "event",
    "entityType",
    "isEditableTags",
    "listApi",
    "role",
    "usersData",
    "inviteModalInstance",
    "getValidEmail"
  ],
  watch: {
    selectedUsers(old, newVal) {
      this.$emit("input", this.selectedUsers);
    },
    role(val, oldVal) {
      this.handleOutsideClick(null, true);
    }
  },
  methods: {
    filterByInvitations() {
      return true;
    },
    handleOutsideClick(event, isKeyEvents) {
      const clickedElement = event && event.target;
      if (
        (clickedElement &&
          !this.$el.contains(clickedElement) &&
          !this.$refs.select.$el.contains(event.target)) ||
        isKeyEvents
      ) {
        if (this.$refs && this.$refs.select && this.$refs.select.search) {
          this.filterData(this.$refs.select.search);

          this.$nextTick(() => {
            const search = this.$refs.select.search;
            this.$refs.select.search = "";
            this.emailValidator(search);
          });
        }
      }
    },
    async filterData(user) {
      this.isTrue = false;
      if (user) {
        this.filter = [
          {
            field: "all",
            operator: "ilike",
            value: user
          }
        ];
        // await this.getUsersList(this.filter);
        let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (emailRegex.test(user)) {
          this.newEmail = this.$t("user.NewEmail", {
            name: user
          });
        } else {
          this.newEmail = this.$t("user.InvalidEmail");
        }
      } else {
        this.availableUsers = [];
      }
      if (user) {
        this.getValidEmail(true);
      } else {
        this.getValidEmail(false);
      }
    },

    emailValidator(parameter) {
      if (this.selectedUsers.length > 20) {
        this.selectedUsers.pop();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("user.MaxInvitationsTitle"),
            text: this.$t("user.MaxInvitations"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return;
      }
      const users = parameter.split(/[ ,]/).map((e) => String(e || "").trim());
      let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (Array.isArray(users)) {
        const validEmails = users.filter((user) => emailRegex.test(user));

        const invalidEmails = [];
        for (let index = 0; index < users.length; index++) {
          const email = users[index];
          if (emailRegex.test(email)) {
            if (!this.selectedUsers.includes(email)) {
              if (this.selectedUsers.length > 20) {
                this.selectedUsers.pop();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("user.MaxInvitationsTitle"),
                    text: this.$t("user.MaxInvitations"),
                    icon: "InfoIcon",
                    variant: "danger"
                  }
                });
                return;
              } else {
                this.selectedUsers.push(email);
              }
            }
          } else {
            if (email && email !== ",") {
              invalidEmails.push(email);
            }
          }

          // this.tagFormatter([email]);
        }
        if (invalidEmails && invalidEmails.length) {
          setTimeout(() => {
            this.$refs.select.search = invalidEmails.toString();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("user.InvalidEmailTitle"),
                text: this.$t("user.InvalidEmail"),
                icon: "InfoIcon",
                variant: "danger"
              }
            });
            this.newEmail = this.$t("user.InvalidEmail");
            this.getValidEmail(true);
          }, 100);
        } else {
          this.getValidEmail(false);
        }
      }
    },

    async tagFormatter(user) {
      if (this.selectedUsers.length > 20) {
        this.selectedUsers.pop();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("user.MaxInvitationsTitle"),
            text: this.$t("user.MaxInvitations"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return;
      }

      let lastElement = user && user[user.length - 1];
      if (lastElement) {
        this.selectedUsers.pop();
        this.emailValidator(lastElement);
      }
    },
    async getUsersList(filter) {
      try {
        let response = await new AccountService().getUsersList({
          filters: filter
        });
        if (response && response.data) {
          this.availableUsers = response.data.list;
          this.availableUsers = this.availableUsers.map((a) => {
            a.variant = this.getRandomBgColor();
            a.avatar_txt =
              a.first_name &&
              a.first_name.substring(0, 1) +
                (a.last_name && a.last_name ? a.last_name.substring(0, 1) : "");
            (a.id = a.id), (a.username = a.username);
            a.profile_pic_url = a.profile_pic_url;
            a.name = a.name;
            a.isNew = true;
            a.isAccepted = a.isAccepted;
            this.disabled = a.isAccepted ? true : false;
            this.isTrue = true;
            return a;
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
.tag-select {
  .v-select .vs__dropdown-menu .vs__no-options {
    display: none;
  }
  ul.vs__dropdown-menu {
    max-height: 160px;
  }
  &.disabled {
    border-radius: 6px;
    background-color: var(--white3) !important;
  }
}
.multi-invitation {
  .vs__selected {
    display: flex !important;
    align-items: center;
  }
}
.multi-invitation-1 {
  .vs__dropdown-option:has(.disabled-content) {
    opacity: 0.5;
    pointer-events: none !important;
    cursor: none !important;
  }
  .vs__dropdown-option:first-child {
    display: none;
  }
  .vs__dropdown-option:has(.is-new-content) {
    display: block;
  }
}
</style>

<template>
  <div>
    <SentInvitations
      :refreshed="refreshed"
      :ison="page"
      :selectedUser="{}"
      :selectedAccount="selectedAccount"
      :roles="roles"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="skeleton-w">
        <div class="table-responsive" v-if="show">
          <b-skeleton-table
            v-if="show"
            :rows="10"
            :columns="7"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>
      </div>
      <b-table
        class="position-relative no-headers user-invitations-list"
        responsive
        v-if="!show"
        show-empty
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->

        <template #cell(user)="row">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">
              {{ row.item.email }}
            </h6>
            <small class="text-muted">{{ row.item.role_name }}</small>
          </b-media>
        </template>
        <template #cell(status)="row">
          <b-badge
            v-if="row.item.status"
            :class="
              row.item.status === 'Accepted'
                ? 'badge-cust-accepted'
                : row.item.status === 'Declined'
                ? 'badge-cust-decline'
                : row.item.status === 'Expired'
                ? 'badge-cust'
                : 'badge-cust-pending'
            "
          >
            {{ $t(`user.Status.${row.item.status}`) }}
          </b-badge>
        </template>
        <template #cell(action)="row">
          <div class="text-center" v-if="row.item.is_resent">
            <span :id="row.item.id">
              <feather-icon
                v-b-tooltip.hover.left.v-primary
                :title="$t('tooTip.resend')"
                v-if="
                  row.item.status === 'Declined' ||
                  row.item.status === 'Expired' ||
                  row.item.status === 'Pending'
                "
                icon="RefreshCwIcon"
                class="text-primary action-icon"
                size="18"
                style="position: relative; cursor: pointer"
                @click="resend(row.item)"
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalUsers > 0"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-accept-invite"
      centered
      size="sm"
      modal-class="no-header-modal"
    >
      <b-card-text>
        <div class="d-flex justify-content-center">
          <b-avatar variant="light-primary" size="96" class="size-96">
            <feather-icon size="37" icon="UserPlusIcon" />
          </b-avatar>
        </div>
        <div class="new-invite-title">{{ $t("user.NewInvite") }}</div>
        <div class="you-invite">{{ $t("user.YouInvite") }}</div>
        <div class="invite-name">"{{ $t("user.InviteName") }}"</div>
        <div class="d-flex justify-content-center mb-64 mtt-50">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mt-2 mr-1"
          >
            {{ $t("user.Accept") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            type="reset"
            class="mt-2"
          >
            {{ $t("user.Decline") }}
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <!-- <Loader :show="show" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BModal,
  BForm,
  BTooltip,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import AccountService from "@/libs/api/account-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SentInvitations from "@/layouts/components/UserOnboarding/SentInvitations.vue";
import store from "@/store";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    Loader,
    BFormGroup,
    SentInvitations,
    BModal,
    BForm,
    BTooltip,
    VBTooltip,
    BSkeletonTable
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      page: "invite",
      tableColumns: [
        { key: "user", label: this.$t("historyTableColumns.account") },
        { key: "status", label: this.$t("historyTableColumns.status") },
        { key: "action", label: this.$t("historyTableColumns.action") }
      ],
      totalUsers: 0,
      user: {},
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      items: [],
      roles: [],
      show: false,
      is_allow_sent_invite: true,
      timeoutId: null,
      isAddRole: false
    };
  },
  props: ["selectedAccount", "isSentInvite"],
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.getInvitationList();
    }, 300); // Adjust the delay as necessary

    this.getRoles();
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getInvitationList();
      }, 300);
    },
    currentPage(newPage) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getInvitationList();
      }, 300);
    },
    filter() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getInvitationList();
      }, 300);
    }
  },
  methods: {
    async getInvitationList() {
      try {
        const isAllowSentInvitation =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? this.checkAbility({
                  action: this.constants.PERMISSIONS_ACTION.READ,
                  subject: this.constants.PERMISSIONS_MODEL.VW_USER_ACCOUNTS
                })
              : this.selectedAccount.type === "CONSUMER"
              ? this.checkAbility({
                  action: this.constants.PERMISSIONS_ACTION.READ,
                  subject: this.constants.PERMISSIONS_MODEL.VW_CHILDREN_ACCOUNTS
                })
              : this.checkAbility({
                  action: this.constants.PERMISSIONS_ACTION.READ,
                  subject: this.constants.PERMISSIONS_MODEL.VW_USER_ACCOUNTS
                })
            : this.checkAbility({
                action: this.constants.PERMISSIONS_ACTION.READ,
                subject:
                  this.constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
              });

        if (!isAllowSentInvitation) {
          return;
        }
        this.show = true;

        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().getSubDealerAccountInvitationList({
                  page: this.currentPage,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null,
                  page_size: parseInt(this.perPage)
                })
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().getSubConsumerAccountInvitationList({
                  page: this.currentPage,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null,
                  page_size: parseInt(this.perPage)
                })
              : await new AccountService().getSubAccountInvitationList({
                  page: this.currentPage,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null,
                  page_size: parseInt(this.perPage)
                })
            : await new AccountService().getInvitationList({
                page: this.currentPage,
                account_id:
                  (this.selectedAccount && this.selectedAccount.id) || null,
                page_size: parseInt(this.perPage)
              });

        if (response && response.data) {
          this.show = false;
          this.items = response.data.invitationList || [];

          this.is_allow_sent_invite =
            this.selectedAccount && this.selectedAccount.id
              ? this.selectedAccount.type === "DEALER"
                ? this.checkAbility({
                    action: this.constants.PERMISSIONS_ACTION.UPDATE,
                    subject: this.constants.PERMISSIONS_MODEL.VW_USER_ACCOUNTS
                  })
                : this.selectedAccount.type === "CONSUMER"
                ? this.checkAbility({
                    action: this.constants.PERMISSIONS_ACTION.UPDATE,
                    subject:
                      this.constants.PERMISSIONS_MODEL.VW_CHILDREN_ACCOUNTS
                  })
                : this.checkAbility({
                    action: this.constants.PERMISSIONS_ACTION.READ,
                    subject: this.constants.PERMISSIONS_MODEL.VW_USER_ACCOUNTS
                  })
              : this.checkAbility({
                  action: this.constants.PERMISSIONS_ACTION.UPDATE,
                  subject:
                    this.constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
                });
          this.items = this.items.map((l) => {
            l.is_resent = this.is_allow_sent_invite;
            l.variant = this.getRandomBgColor();
            l.avtar_txt = this.userAvatarTxt(l.email);

            return l;
          });
          this.totalUsers = response.data.count || 0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.getUserInvitations();
    },
    async resend(userData) {
      try {
        this.show = true;
        const body = {
          email: userData.email,
          role: userData.role,
          account_id: (this.selectedAccount && this.selectedAccount.id) || null,
          is_resend: true,
          status: userData.status
        };
        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().inviteUserSubDealerAccount(body)
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().inviteUserSubConsumerAccount(body)
              : await new AccountService().inviteUserSubAccount(body)
            : await new AccountService().inviteUser(body);
        if (response && response.data) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("user.InvitationSentTitle"),
              text: this.$t("user.InvitationReSent"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.getInvitationList();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async refreshed() {
      this.getInvitationList();
    },
    async getRoles() {
      try {
        let obj = {};
        if (this.selectedAccount && this.selectedAccount.id) {
          obj = {
            account_id: this.selectedAccount.id,
            type: this.selectedAccount.type
          };
        }
        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().getSubDealerAccountUserRoles(obj)
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().getSubConsumerAccountUserRoles(obj)
              : await new AccountService().getSubAccountUserRoles(obj)
            : await new AccountService().getUserRolesRead(obj);

        if (response && response.data) {
          this.roles = response.data.list || [];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.user-invitations-list {
  overflow-y: auto;
  height: calc(100vh - 315px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.table-responsive {
  height: calc(100vh - 315px);
}
.new-invite-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
  margin-top: 32px;
}
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.invite-name {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  font-weight: 600;
  word-break: break-all;
}
.badge-cust {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--red2);
  padding: 4px 9px !important;
}
.badge-cust-accepted {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--green2);
  padding: 4px 9px !important;
}
.badge-cust-pending {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--green3);
  padding: 4px 9px !important;
}
.badge-cust-decline {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: $yellow;
  padding: 4px 9px !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SentInvitations',{attrs:{"refreshed":_vm.refreshed,"ison":_vm.page,"selectedUser":{},"selectedAccount":_vm.selectedAccount,"roles":_vm.roles}}),(_vm.isSentInvite)?_c('UpdateUserRole',{attrs:{"ison":_vm.page,"refreshed":_vm.refreshed,"selectedUser":_vm.selectedUser,"selectedAccount":_vm.selectedAccount,"roles":_vm.roles}}):_vm._e(),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(_vm.show)?_c('div',{staticClass:"table-responsive"},[(_vm.show)?_c('b-skeleton-table',{attrs:{"rows":10,"columns":1,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1):_vm._e(),(!_vm.show)?_c('b-table',{staticClass:"position-relative no-headers userlist-table",attrs:{"responsive":"","show-empty":"","align-v":"end","items":_vm.items,"fields":_vm.tableColumns,"empty-text":_vm.$t('NoMatchingRecordsFound')},scopedSlots:_vm._u([{key:"cell(user)",fn:function(row){return [_c('b-media',{staticClass:"align-item-center",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","src":row.item.profile_pic_url,"text":row.item.avtar_txt,"variant":row.item.variant}})]},proxy:true}],null,true)},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(row.item.first_name)+" "+_vm._s(row.item.last_name)),_c('span',{staticClass:"text-primary"},[_vm._v(" ("+_vm._s(row.item.primary_role)+") ")])]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(row.item.email))])])]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticClass:"text-right list-icon"},[_c('span',{attrs:{"id":row.item.up_tool}},[(
                _vm.selectedAccount && _vm.selectedAccount.id
                  ? _vm.selectedAccount.type === 'DEALER'
                    ? _vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                        subject: _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT
                      })
                    : _vm.selectedAccount.type === 'CONSUMER'
                    ? _vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                        subject:
                          _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT_CONSUMER
                      })
                    : _vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                        subject: _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT
                      })
                  : _vm.checkAbility({
                      action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                      subject: _vm.constants.PERMISSIONS_MODEL.USERS
                    }) && row.item.isEditable
              )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.update')),expression:"$t('tooTip.update')",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary action-icon",staticStyle:{"position":"relative","margin-right":"10px","cursor":"pointer"},attrs:{"icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.updateUserRoll(row.item)}}}):_vm._e()],1),_c('span',{attrs:{"id":row.item.del_tool}},[(
                _vm.selectedAccount && _vm.selectedAccount.id
                  ? _vm.selectedAccount.type === 'DEALER'
                    ? _vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                        subject: _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT
                      })
                    : _vm.selectedAccount.type === 'CONSUMER'
                    ? _vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                        subject:
                          _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT_CONSUMER
                      })
                    : _vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                        subject: _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT
                      })
                  : _vm.checkAbility({
                      action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                      subject: _vm.constants.PERMISSIONS_MODEL.USERS
                    }) && row.item.isDeletable
              )?_c('feather-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-sm-remove",modifiers:{"modal-sm-remove":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.delete')),expression:"$t('tooTip.delete')",modifiers:{"hover":true,"top":true}}],staticClass:"text-danger action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.close(row.item)}}}):_vm._e()],1)])]}}],null,false,1770447999)}):_vm._e(),_c('div',{staticClass:"mx-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.totalUsers > 0)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","no-paging-nav":false,"active-class":_vm.activeClass},on:{"input":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1),_c('AreYouSureModal',{attrs:{"data":_vm.user,"onClose":_vm.onClose,"removedUser":_vm.removedUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
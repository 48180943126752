var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isGroupEntity)?_c('b-card',{staticClass:"mt-36 added-data"},[_c('div',{staticClass:"adduser-btn-row"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("user.Userlist"))+" ")])]),(_vm.isGroupEntity)?_c('UserListComp'):_vm._e()],1):_vm._e(),(!_vm.isGroupEntity)?_c('b-tabs',{attrs:{"pills":""}},[(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.READ,
          subject: _vm.constants.PERMISSIONS_MODEL.USERS
        })
      )?_c('b-tab',{attrs:{"active":_vm.selected_tab === 'users',"lazy":""},on:{"click":function($event){return _vm.activeTab('users', false)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("user.User")))])]},proxy:true}],null,false,2624454547)},[_c('b-card',{staticClass:"mt-36 added-data"},[_c('div',{staticClass:"adduser-btn-row"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("user.Userlist"))+" ")]),(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.ADD,
                subject: _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
              })
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"btn-icon",attrs:{"title":_vm.$t('tooTip.AddInvite'),"variant":"primary"},on:{"click":_vm.updateUserRoll}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}}),_c('span',{staticClass:"d-none d-sm-inline ml-1"},[_vm._v(_vm._s(_vm.$t("tooTip.AddInvite")))])],1):_vm._e()],1),_c('UserListComp',{attrs:{"isSentInvite":_vm.isSentInvite}})],1)],1):_vm._e(),(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.READ,
          subject: _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
        })
      )?_c('b-tab',{attrs:{"active":_vm.selected_tab === 'invitations',"lazy":""},on:{"click":function($event){return _vm.activeTab('invitations', false)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserPlusIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("user.invitations")))])]},proxy:true}],null,false,331393306)},[_c('b-card',{staticClass:"mt-36 added-data"},[_c('div',{staticClass:"adduser-btn-row"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("user.ListInvitations"))+" ")]),(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.ADD,
                subject: _vm.constants.PERMISSIONS_MODEL.USER_ACCOUNT_INVITATION
              })
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"btn-icon",attrs:{"title":_vm.$t('tooTip.AddInvite'),"variant":"primary"},on:{"click":_vm.updateUserRoll}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}}),_c('span',{staticClass:"d-none d-sm-inline ml-1"},[_vm._v(_vm._s(_vm.$t("tooTip.AddInvite")))])],1):_vm._e()],1),_c('InvitationsList',{attrs:{"isSentInvite":_vm.isSentInvite}})],1)],1):_vm._e(),(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.READ,
          subject: _vm.constants.PERMISSIONS_MODEL.ROLE
        })
      )?_c('b-tab',{attrs:{"active":_vm.selected_tab === 'roles',"lazy":""},on:{"click":function($event){return _vm.activeTab('roles', false)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"ToolIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("roles_management.Roles")))])]},proxy:true}],null,false,2909680246)},[_c('RolesList')],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }